@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Righteous&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

/* Global */

body {
  background-color: #f5f3f371 !important;
  margin: 0 auto;
  color: #000 !important;
  line-height: 1.6;
  /* font: 400 15px/28px "Poppins", sans-serif!important; */
  /* font-family: 400 15px/28px "Montserrat", sans-serif !important; */
  font-family: "Poppins", sans-serif !important;
  overflow-x: hidden !important;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: 600 !important;
}

/* Scrollbar  */

::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  border: none;
  background-color: whitesmoke;
}
::-webkit-scrollbar-thumb {
  background: #096b00;
  height: 3rem !important;
  border-radius: 10px;
}

/* Scrollbar  */

section {
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.hide {
  display: none !important;
}

.green-word {
  color: #096b00 !important;
}
.green {
  background-color: #096b00 !important;
}

/* Modal Props */
.modal section {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.modal-dialog .modal-content {
border-radius: 1.5rem !important;
}

.modal-header {
  border-bottom: none !important;
}

button.close span {
  display: none !important;
}

.close {
  box-sizing: content-box;
  width: 1em !important;
  height: 1em;
  padding: .25em;
  color: #000 !important; 
  background: transparent url(/src/Components/User/img/close.svg) 50%/1em auto no-repeat !important;
  border: 0;
  border-radius: .25rem;
  opacity: .5;
}
/* Modal Props */

/* Back Button  */

/* Back Button  */

/* Global */
/* Global */


 /* Navbar */
 nav {
  background-color: #f5f3f3f6!important;
}

/* nav .navbar-brand {
  width: 15%!important;
} */

/* .active1 {
  background-color: #096b00 !important;
 // border-radius: 2rem;
  padding: .5rem 2rem  !important;
transition: 0.1s ease!important;
} */

nav button.navbar-toggler {
  background-color: none!important;
}

.navbar-toggler-icon {
  background: none !important;
}

nav .nav-item a {
  color: #fff;
  font-size: 1rem;
  display: block;
  padding: .5rem 1rem;
  margin: 0 1rem ;
}

/* nav .nav-item a:hover {
  border: #fc4646d0 solid 2px !important;
  border-radius: 2rem;
  padding: .5rem 2rem  !important;
transition: 0.1s ease!important;
} */
/* 
nav .dropdown-menu  a:hover {
  border: #fc4646d0 solid 1px !important;
  border-radius: .3rem;
transition: 0 !important;
} */

nav .btn {
  text-transform: uppercase!important;
  padding: 0.6rem 0.7rem !important;
  /* border: #000 solid 2px !important; */
  /* border-radius: 5px; */
  width: 5rem !important;
  /* color: #000; */
}

nav .btn2 {
background: #096b00;
color: #fff;
border:transparent solid 1px !important;
}
nav .btn2:hover {
  background: transparent !important;
  color: #096b00 !important;
  border: #096b00 solid 1px !important;
  transition: 0.1s ease!important;
}
nav .btn3 {
  background: transparent;
  color: #096b00;
  border: #096b00 solid 1px !important;
  }

  nav .btn3:hover {
    background: #096b00 !important;
    color: #fff !important;
    border:transparent solid 1px !important;
    transition: 0.1s ease!important;
  }

  nav .btn4 {
    background: #096b00;
color: #fff;
border:transparent solid 1px !important;
  width: 7rem !important;
  }

  nav .btn4:hover {
    background: transparent !important;
    color: #096b00 !important;
    border: #096b00 solid 1px !important;
    transition: 0.1s ease!important;
  }
/* Navbar */

/* Homepage  */
/* Homepage  */

/* Showcase */
.showcase {
  margin: 0;
  padding: 0;
  background: linear-gradient(#0000008c, #0000008c), url(../Components/HomePage/img/showcase.png) no-repeat;
  background-size: cover;

  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
}

.showcase .showcase-content {
  margin-top: 30vh;
  color: #ffffff;
  text-align: justify;
}

.showcase .showcase-content h1 {
  font-weight: 100 !important;
  font-family: "Righteous", cursive !important;
}

/* Showcase */

/* Tracking  */

.tracking .form-control {
  border: #fff !important;
  color: #000 !important;
}

.tracking .form-control:focus {
  color: #fff;
  background-color: #fff !important;
  border-color: #fff !important;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgb(1 1 1 / 0%) !important;


}

.tracking .button {
  border-radius: 0 5rem 5rem 0 !important;

}



.tracking a {
  background-color: #fff;
  border-left: 4px solid #096b00 !important;
  border-radius: 0 5rem 5rem 0 !important;

  margin: 0 !important;

}

.tracking span {
  background-color: transparent;
  margin: 0 !important;

}

.tracking box-icon {

  padding: 0 1rem !important;

}

/* Tracking  */


/* What We Do  */

.whatwedo {
  color: #096b00;
  font-family: "Poppins", sans-serif;
}
.whatwedo .img {
  border-radius: 1rem !important;
  padding: 2rem;
}
/* .whatwedo .col-md-3{
  height: 50% !important;
} */

.whatwedo .content {
  margin-top: 2rem;
}

.whatwedo .view-btn button {
  background-color: #096b00;
  border-radius: 50px;
  color: #fff;
  padding: 1.2rem 5rem !important;
  margin-top: 2rem !important;
}
/* What We Do  */

/* How To Send  */
.howtosend {
  color: #096b00;
  font-family: "Poppins", sans-serif;
  background: #94ff8a33;
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.howtosend .content {
  margin-top: 2rem;
}

.howtosend .view-btn button {
  background-color: #096b00;
  border-radius: 50px;
  color: #fff;
  padding: 1.2rem 5rem !important;
  margin-top: 2rem !important;
}
/* How To Send  */

/* How It Works  */
.howitworks {
  padding: 5rem 0;
  /* background-color: #fff; */
}


.howitworks .card1 {
  margin: 5rem 0;
}

.howitworks .card2 {
  margin: 5rem 0;
}

.howitworks h6 {
  max-width: 560px !important;
  text-align: center;
  font-weight: 500 !important;
}

/* How It Works  */

/* Homepage  */
/* Homepage  */

/* Get Quote */
.get-quote {
  background: #096b00ec;
  padding-top: 5rem;
  color: #fff !important;
}

.get-quote .user-btn button {
  margin-top: 7px !important;
  background: white !important;
  color: #096b00 !important;
}
/* Get Quote */


/* Login and SignUp  */
/* Login and SignUp  */
/* Login  */
.login {
  /* height: 100vh !important; */
  overflow-x: hidden;
  overflow-y: hidden;
  font-style: normal;
  font-weight: normal;
  animation:ease;
  margin-top: 3rem !important;
}

.login h6, .login label {
  font-size: smaller !important;

}
.login .login-img {
  height: 100% !important;
    position: fixed !important;
    background-color: #7c4d4d;
    overflow-x: hidden !important;
    overflow-y: hidden !important;
    z-index: 900 !important;

}

.login .login-form {
  margin-left: 50% !important;
}


.login img {
  height: 100vh !important;
  object-fit: cover;
  
}

.login .input-style {
  width: 100%;
  padding: 12px 20px;
  /* margin: 8px 0; */
  display: inline-block;
  border: 1px solid #828282;
  box-sizing: border-box;
  border-radius: 10px;
  font-size: 12px;
  line-height: 24px;
  color: #000;
  box-shadow: none;
  height: 45px;
  margin-bottom: 1rem !important;
  /* -webkit-appearance: textfield !important; */
}

.login .input-style:focus,
.login .input-style:hover {
  border: 1px solid #096b00 !important;
}

.login .btn {
  background-color: #096b00;
  border-radius: 10px;
  color: #ffffff !important;
  text-align: center !important;
  width: 100% !important;
}

.login #togglePassword, #togglePassword2 {
  font-size: 22px;
}
.login .toggle-eye {
  right: 5%;
  top: 25%;
  position: absolute;
  cursor: pointer;
  font-weight: bolder;
  display: block;
}
/* Login  */
/* Login and SignUp  */
/* Login and SignUp  */


/* Footer */
.footer {
  background-color: #111;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.footer h5 {
  font-weight: 100 !important;
}
.footer button {
  background-color: #096b00;
  border-radius: 50px;
}

.footer h6 {
  color: #096b00;
}

.footer-admin {
  background-color: #111;
}

footer .boxicons .box-icon {
  margin-left: 1rem;
  padding: 0.4rem;
  text-align: center !important;
  background-color: #096b00;
  border-radius: 50%;
  border: transparent solid 1.5px !important;
  transition: 0.15s ease;
}

footer .boxicons .box-icon:hover {
  background-color: #111;
  border: #096b00 solid 1.5px !important;

}

/* Footer */

/* Media Query for Mobile device */
/* Media Query for Mobile device */

@media screen and (max-width: 780px) {

 


  /* Login  */
  .login {
    height: max-content !important;
    overflow-y:visible;
  }
  
  .login h6, .login h5 {
    text-align: center !important;
  }

  
  .login .login-form {
    margin-left: 0 !important;
  }
  

  
  .hide-on-small {
    display:none !important;
  }
  }
  


@media screen and (max-width: 580px) {
   /* Global */
   .userback {
    display: none !important;
  }
  /* Global */
  .hide {
    display: contents !important;
  }
  /* Showcase  */

  .showcase .showcase-content {
    margin-top: 35vh;
  }

  /* Showcase  */


  /* How It Works  */
  .howitworks .card2 .row {
    flex-direction: column-reverse !important;
  }
  /* How It Works  */
}
