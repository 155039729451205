/* Navbar */

.usernav nav {
  background-color: #fafafa !important;
  transition: 0.5s ease !important;
  padding: 0.5rem 1rem !important;
  z-index: 1001 !important;
  text-align: left !important;
  border-radius: 0 0 1.5rem 1.5rem !important;
  /* box-shadow: none !important; */

  /* height: 7rem ; */
}

.usernav .active1 {
  border-right: #15df02 5px solid;
  color: #b4f3ae !important;
  transition: 0.1s ease !important;
  /* background: #fafafa !important;
border-radius: 4px;
color: #096b00 !important; */
}
.usernav .active2 {
  background: yellow !important;
}

.usernav nav button.navbar-toggler {
  background-color: none !important;
}

.usernav .navbar-toggler-icon {
  background: none !important;
}





.usernav nav .nav-item a {
  width: 100% !important;
  /* padding-left: 0.4rem !important; */
}

.usernav nav .nav-item {
  display: flex !important;
  align-items: center !important;
  width: auto !important;
  margin-left: 0.5rem !important;
  /* margin-right: 0.5rem !important; */
  /* background: yellow; */
}

.usernav nav .nav-item a:hover {
  border-right: #fff 5px solid !important;
  color: #fff !important;
  transition: 0.1s ease !important;
  background: #096b0059;
  border: 0 5px 0 0 !important;
}

/* usernav dropdown  */

.usernav nav .nav-item .dropdown-user {
  /* border: none !important ; */
  background: white !important;
  text-align: center !important;
  border-radius: 0.3rem !important;
  font-size: x-small;
}

.usernav nav .dropdown .user:hover {
  border-right: none !important;
  color: #fff !important;
  transition: 0.1s ease !important;
  background: #096b0059;
  border: 0 5px 0 0 !important;
}

.details p{
  font-size: 17px !important;
  margin-bottom: 0 !important;
}

.usernav nav .details a {
  width: 0 !important;
  padding-left: 0.4rem !important;
}

.usernav nav .details a:hover {
  border-right: none !important;
  color: none !important;
  transition: none !important;
  background: none;
  border: none !important;
}


.usernav nav .details .btn {
  margin: 0 !important;
  padding: 0.4rem 0.8rem !important;
}

.usernav nav .btn {
  text-transform: uppercase !important;
  padding: 0.8rem 1rem !important;
  width: 7rem !important;
}


.icon {
  position: relative;
  /* font-size: 48px; */
  color: #f9f9f9;
}

.icon::after {
  content: "";
  position: absolute;
  text-align: center !important;
  /* top: 6px; */
  right: -5px;
  width: 12px;
  height: 12px;
  font-size: 8px;
  border-radius: 50%;
  background: red;
  color: #f9f9f9;
}
/* Admin Nav  */
/* .usernav .adminnav {

} */
/* Admin Nav  */

/* Navbar */
.sidenav {
  height: 100%;
  width: 200px;
  position: fixed;
  top: 0rem;
  /* padding-top: 3rem; */
  left: 0;
  background-color: #096b00;
  overflow-x: hidden;
  transition: cubic-bezier(0.075, 0.82, 0.165, 1) 0.5s;
  z-index: 1002 !important;
}

.sidenav ul li {
  margin-bottom: 1rem;
}

.sidenav .nav-item a {
  color: #fafafa;
  font-size: 1rem;
  display: block;
  padding: 0.5rem 1rem;
  margin: 0 0rem;
}

.sidenav .btn {
  text-transform: uppercase !important;
  padding: 0.8rem 1.3rem !important;
  border: #fafafa solid 2px !important;
  border-radius: 5px;
  color: #fafafa;
}
.sidenav .btn:hover {
  background-color: #fafafa !important;
  transition: 0.55s ease !important;
  color: #000000 !important;
}

.sidenav .closebtn {
  position: absolute;
  /* top: 25px; */
  right: 15px;
  font-size: 36px;
  margin-left: 50px;
  display: none;
  /* background-color: red; */
  border-radius: 2rem;
  /* border: 1px white solid; */
}

.name {
  text-align: center !important;
  margin-left: 0.5rem !important;
}

.sidenav h5 {
  font-weight: 100 !important;
  color: aliceblue;
}
.sidenav button.btn1 {
  background-color: #096b00 !important;
  border-radius: 50px !important;
  padding: 1rem 3rem !important;
  border: none !important;
}

.sidenav button.btn1:hover {
  background-color: #fff !important;
  color: #096b00 !important;
}

#main {
  transition: margin-left 0.5s;
  padding: 16px;
}

@media screen and (max-width: 991px) {
  .usernav .navbar ul {
    display: none !important;
  }
}

@media screen and (max-width: 580px) {
  /* Navbar */
  .nav-name {
    display: none !important;
  }

  .sidenav {
    width: 0;
    /* padding-top: 3rem; */
  }

  .sidenav .closebtn {
    display: block !important;
  }
  h5.name {
    text-align: right !important;
    margin-left: 0.5rem !important;
  }
}

@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }
  .sidenav a {
    font-size: 18px;
  }
}
