/* Dashboard  */
/* Dashboard  */
.dashboard {
  margin-left: 200px !important;
  margin-top: 4rem !important;
}

/* Dashboard Header  */

.dash-header .welcome {
  margin: 0;
  padding: 0;
  background: linear-gradient(#096b00c2, #096b00c2),
    url(../Components/User/Dashboard/img/pattern2.jpg) no-repeat;
  background-size: cover;
  overflow-x: hidden;
  border-radius: 1rem;
  color: #ffffff !important;
  padding: 1rem 2rem;
}

.dash-header .balance {
  padding: 1.5rem 0rem;
}

.dash-header .balance .spin {
  font-weight: 200 !important;
}

.dash-header .balance h5 {
  font-weight: 100 !important;
}

.dash-header .balance h3 {
  font-weight: 800 !important;
  font-family: "Righteous", cursive !important;
  font-size: xx-large;
}

.dash-header .second {
  margin-top: 1rem;
  background: linear-gradient(#096b00c2, #096b00c2),
    url(../Components/User/Dashboard/img/pattern2.jpg) no-repeat;
  background-size: cover;
  overflow-x: hidden;
  padding: 1rem 1rem;
  border-radius: 1rem;
  color: #ffffff !important;
}

.dash-header .second h6 {
  font-weight: 100 !important;
  text-transform: uppercase !important;
}

.dash-header .third {
  margin-top: 1rem;
  background-size: cover;
  overflow-x: hidden;
  padding: 0.3rem 1rem;
  border-radius: 1rem;
  color: #096b00 !important;
  background: transparent !important;
}

.dash-header .third h6 {
  font-weight: 100 !important;
  margin-top: 0.5rem;
}

.dash-header .box-icon {
  padding: 0.4rem;
  text-align: center !important;
  background-color: #096b001f;
  border-radius: 50%;
  transition: 0.15s ease;
}

/* Dashboard Header  */

/* Transaction History  */
.send-package.first {
  margin-left: 0px !important;
}

/* History Tab */

.trans-history ul {
  background-color: #fff;
  width: max-content !important;
  border-radius: 10px !important;
  padding: 2px !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

.trans-history a.nav-link {
  font-size: 1.2rem;
  color: #000;
  font-weight: 500 !important;
  padding: 0.5rem 2rem;
  transition: 0.7s !important;
}

.trans-history .nav-tabs .nav-link {
  border: 1px solid transparent;
  border-radius: 10px !important;
}

.trans-history .nav-link.active {
  background-color: #096b00 !important;
  border-radius: 10px !important;
  color: #fff !important;
}

.trans-history .nav-link:hover {
  border: 1px solid #096b00 !important;
  border-radius: 10px !important;
  /* color: #000!important; */
}

.trans-history .nav-link:focus {
  color: #fff !important;
}
/* History Tab */

.history .first {
  background: #15e40217;
  margin-bottom: 1rem !important;
  padding: 0.5rem 1rem 0 1rem !important;
  border-radius: 1rem;
}

.history .first .bold-text {
  font-weight: 900 !important;
}

.trans-history a {
  font-size: smaller !important;
}

.trans-history #myTab {
  margin-top: 10px !important;
}

/* Transaction History  */

/* Dashboard  */
/* Dashboard  */

/* Send Package Page */
/* Send Package Page */

.send-package {
  margin-left: 200px !important;
  margin-top: 1rem !important;
}

.send-package .sendshowcase {
  width: auto !important;
  height: 40vh !important;
}

.send-package .sendshowcase .showcase-content {
  margin-top: 7vh !important;
  color: #ffffff;
  text-align: justify;
}

.send-package #myTab {
  margin-top: 3rem;
}
.send-package a.nav-link {
  font-size: 1.2rem;
  color: #000;
  font-weight: 500 !important;
  padding: 0.5rem 2rem;
}

.send-package .nav-tabs .nav-link {
  border: 1px solid transparent;
  border-radius: 10px !important;
}

.send-package .nav-link.active {
  background-color: #096b00 !important;
  border-radius: 10px !important;
  color: #fff !important;
}

.send-package .nav-link:hover {
  border: 1px solid #096b00 !important;
  border-radius: 10px !important;
  color: #000 !important;
}

.send-package .nav-link:focus {
  color: #fff !important;
}

/* Send Package Form  */
.send {
  margin-top: 3rem;
  margin-bottom: 5rem;
  color: #000 !important;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
}

.form {
  margin-top: 2rem;
}

* {
  outline: none;
  -webkit-tap-highlight-color: transparent;
}

.input-style {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #828282;
  box-sizing: border-box;
  border-radius: 10px;
  font-size: 18px;
  line-height: 24px;
  color: #000;
  box-shadow: none;
  height: 55px;
  margin-bottom: 1.5rem !important;
  /* -webkit-appearance: textfield !important; */
}

.send select {
  background: url(../Components/User/img/dropdown.svg) no-repeat right #ddd0;
  -webkit-appearance: none;
  background-position-x: 96%;
  background-color: white;
  background-position-y: 52%;
  color: #828282 !important;
}

.send .textarea-style {
  height: 100px;
  font-size: 15px;
}

.send .input-style:hover,
.send .input-style:active {
  border: 1px solid #096b00;
}

.send .btn {
  background-color: #096b00;
  color: #ffffff !important;
  text-align: center !important;
  border-radius: 2rem !important;
}

.user-btn button {
  background-color: #096b00;
  border-radius: 10px;
  color: #fff;
  padding: 1.2rem 5rem !important;
  margin-top: 2rem !important;
  /* font-size: large !important; */
}

/* Send Package Form  */

/* Invoice  */
.send-package .invoice {
  margin-top: 5rem;
}

.send-package .invoice .first {
  border: #096b00 solid 1px;
  border-radius: 1rem;
  padding: 1.5rem 1rem;
}

.send-package .card-header {
  border-radius: 1.5rem 1.5rem 0 0 !important;
}
.send-package .card.curve {
  border-radius: 1.5rem !important;
}

.send-package .invoice .card-body h6 {
  font-size: smaller !important;
}
.send-package .invoice .bolder-text {
  font-weight: 600 !important;
}

.invoice-btn button {
  background-color: #096b00;
  border-radius: 2rem;
  color: #fff;
  padding: 0.6rem 0.2 !important;
}

/* Invoice  */

/* Send Package Page */
/* Send Package Page */

/* Payment */
.payment a {
  font-size: smaller !important;
}

.payment #myTab {
  margin-top: 10px !important;
}

.payment ul {
  background-color: #096b0015;
  width: max-content !important;
  border-radius: 10px !important;
  padding: 2px !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

.payment a.nav-link {
  font-size: 1.2rem;
  color: #000;
  font-weight: 500 !important;
  padding: 0.5rem 2rem;
  transition: 0.7s !important;
}

.payment .nav-tabs .nav-link {
  border: 1px solid transparent;
  border-radius: 10px !important;
}

.payment .nav-link.active {
  background-color: #096b00 !important;
  border-radius: 10px !important;
  color: #fff !important;
}

.payment .nav-link:hover {
  border: 1px solid #096b00 !important;
  border-radius: 10px !important;
  /* color: #000!important; */
}

.payment .nav-link:focus {
  color: #fff !important;
}

/* Payment */

/* Get Quote */
.get-quote a {
  font-size: smaller !important;
}

.get-quote .nav-tabs {
  border-bottom: 1px solid transparent !important;
}

.get-quote #myTab {
  margin-top: 10px !important;
}

.get-quote ul {
  background-color: #096b0015;
  width: max-content !important;
  border-radius: 10px !important;
  padding: 2px !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

.get-quote a.nav-link {
  font-size: 1.2rem;
  color: #fff;
  font-weight: 500 !important;
  padding: 0.5rem 2rem;
  transition: 0.7s !important;
}

.get-quote .nav-tabs .nav-link {
  border: 1px solid transparent;
  border-radius: 10px !important;
}

.get-quote .nav-link.active {
  background-color: #fff !important;
  border-radius: 10px !important;
  color: #096b00 !important;
}

.get-quote .nav-link:hover {
  border: 1px solid #fff !important;
  border-radius: 10px !important;
  /* color: #000!important; */
}

.get-quote .nav-link:focus {
  color: #096b00 !important;
}

/* Get Quote */

/* Track */
/* Track */

.send-package .track {
  margin-top: 2rem;
}
.send-package .track p {
  font-size: smaller !important;
}
.send-package .track ol {
  list-style: none !important;
  margin: 0 !important;
  padding: 0 !important;
}
.send-package .track .eachorder {
  display: flex !important;
  margin-bottom: 0.5rem;
}

.send-package .track .eachorder .first {
  /* background: #044b04f1 !important; */
  border: 1px solid #096b00;
  border-radius: 1rem;
  padding: 1rem 1rem;
}

.send-package .track .img {
  margin-bottom: 0 !important;
}

/* Track */
/* Track */

/* Food */
/* Food */

.food {
  margin-top: 2rem;
  margin-bottom: 5rem;
}

.food .card img.card-img-top {
  height: 10rem;
  object-fit: cover;
  /* padding: 1rem; */
}

.food .card {
  border-radius: 0.2rem 0.2rem 1rem 1rem !important;
}

.food .card p {
  margin-bottom: 0 !important;
  font-size: smaller !important;
}

.food .card-body {
  padding: 0.25rem !important;
}

.food .card p.price {
  font-size: large !important;
  font-weight: 600 !important;
  color: #f86801 !important;
}

.food button {
  background-color: #096b00 !important;
  border-radius: 5px !important;
  color: #fff !important;
  padding: 0.5rem 2rem !important;
  /* margin-top: 1rem !important; */
}
.food .btn-cart {
  background-color: #fff !important;
  border-radius: 5px;
  color: #096b00;
  padding: 0.1rem 2rem !important;
  /* margin-top: 1rem !important; */
}

/* Food Details */

.send-package .food-details {
  margin-top: 5rem;
}

.food-details h5 {
  color: #096b00;
  margin-bottom: 2rem;
  text-align: center;
}

.food-details .curve .card {
  border-radius: 2rem !important;
}
.food-details .card-header {
  border-radius: 2rem 2rem 0 0 !important;
}

.food-details .curve .card img {
  border-radius: 2rem !important;
}

.food-details .details h6 {
  font-size: smaller !important;
}

/* Food Details */

/* Food */
/* Food */

/* Profile  */
/* Profile  */

.settings #togglePassword,
#togglePassword2 {
  font-size: 22px;
}
.settings .toggle-eye {
  right: 5%;
  top: 25%;
  position: absolute;
  cursor: pointer;
  font-weight: bolder;
  display: block;
}

/* Profile  */
/* Profile  */

/* Page Not Found */

.pagenotfound {
  margin: 8rem 0;
}

.pagenotfound .gif {
  color: transparent;
  width: 100%;
  height: 100%;
  object-fit: cover;
  text-align: center;
  text-indent: 10000px;
}

.pagenotfound .giffy {
  width: 100%;
  height: 100%;
  max-width: 450px;
  max-height: 450px;
  display: flex;
  overflow: hidden;
  position: relative;
  font-size: 1.25rem;
  align-items: center;
  flex-shrink: 0;
  line-height: 1;
  user-select: none;
  /* border-radius: 70% 80% 60% 60%; */
  /* border-style: double; */
  border-color: #096b00;
}

.pagenotfound button {
  margin-top: 3rem;
  background-color: #096b00;
  border-radius: 50px;
  color: #ffffff;
}

/* Page Not Found */

/* Media Query for Mobile device */
/* Media Query for Mobile device */

@media screen and (max-width: 780px) {
  /* Login  */
  .login {
    height: max-content !important;
    overflow-y: visible;
  }

  .login h6,
  .login h5 {
    text-align: center !important;
  }

  .hide-on-small {
    display: none !important;
  }
}

@media screen and (max-width: 580px) {
  /* Dashboard  */
  .dashboard {
    margin-left: 0 !important;
  }

  .dashboard .dash-body {
    background: #e9ffe9;
    margin-top: 2rem !important;
    border-radius: 3rem 3rem 0 0 !important;
    height: max-content !important;
    padding-bottom: 3rem !important;
    padding-top: 0.1rem !important;
  }

  .dash-header .first {
    text-align: center !important;
  }

  .dash-header .third h6 {
    font-size: smaller !important;
  }
  /* Dashboard  */

  /* Send Package Page */
  .send-package {
    margin-left: 0 !important;
  }
  /* Send Package Page */
}

/* Media Query for Mobile device */
/* Media Query for Mobile device */
