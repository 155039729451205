@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Righteous&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Global */

body {
  background-color: #f5f3f371 !important;
  margin: 0 auto;
  color: #000 !important;
  line-height: 1.6;
  /* font: 400 15px/28px "Poppins", sans-serif!important; */
  /* font-family: 400 15px/28px "Montserrat", sans-serif !important; */
  font-family: "Poppins", sans-serif !important;
  overflow-x: hidden !important;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: 600 !important;
}

/* Scrollbar  */

::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  border: none;
  background-color: whitesmoke;
}
::-webkit-scrollbar-thumb {
  background: #096b00;
  height: 3rem !important;
  border-radius: 10px;
}

/* Scrollbar  */

section {
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.hide {
  display: none !important;
}

.green-word {
  color: #096b00 !important;
}
.green {
  background-color: #096b00 !important;
}

/* Modal Props */
.modal section {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.modal-dialog .modal-content {
border-radius: 1.5rem !important;
}

.modal-header {
  border-bottom: none !important;
}

button.close span {
  display: none !important;
}

.close {
  box-sizing: content-box;
  width: 1em !important;
  height: 1em;
  padding: .25em;
  color: #000 !important; 
  background: transparent url(/static/media/close.0865c053.svg) 50%/1em auto no-repeat !important;
  border: 0;
  border-radius: .25rem;
  opacity: .5;
}
/* Modal Props */

/* Back Button  */

/* Back Button  */

/* Global */
/* Global */


 /* Navbar */
 nav {
  background-color: #f5f3f3f6!important;
}

/* nav .navbar-brand {
  width: 15%!important;
} */

/* .active1 {
  background-color: #096b00 !important;
 // border-radius: 2rem;
  padding: .5rem 2rem  !important;
transition: 0.1s ease!important;
} */

nav button.navbar-toggler {
  background-color: none!important;
}

.navbar-toggler-icon {
  background: none !important;
}

nav .nav-item a {
  color: #fff;
  font-size: 1rem;
  display: block;
  padding: .5rem 1rem;
  margin: 0 1rem ;
}

/* nav .nav-item a:hover {
  border: #fc4646d0 solid 2px !important;
  border-radius: 2rem;
  padding: .5rem 2rem  !important;
transition: 0.1s ease!important;
} */
/* 
nav .dropdown-menu  a:hover {
  border: #fc4646d0 solid 1px !important;
  border-radius: .3rem;
transition: 0 !important;
} */

nav .btn {
  text-transform: uppercase!important;
  padding: 0.6rem 0.7rem !important;
  /* border: #000 solid 2px !important; */
  /* border-radius: 5px; */
  width: 5rem !important;
  /* color: #000; */
}

nav .btn2 {
background: #096b00;
color: #fff;
border:transparent solid 1px !important;
}
nav .btn2:hover {
  background: transparent !important;
  color: #096b00 !important;
  border: #096b00 solid 1px !important;
  transition: 0.1s ease!important;
}
nav .btn3 {
  background: transparent;
  color: #096b00;
  border: #096b00 solid 1px !important;
  }

  nav .btn3:hover {
    background: #096b00 !important;
    color: #fff !important;
    border:transparent solid 1px !important;
    transition: 0.1s ease!important;
  }

  nav .btn4 {
    background: #096b00;
color: #fff;
border:transparent solid 1px !important;
  width: 7rem !important;
  }

  nav .btn4:hover {
    background: transparent !important;
    color: #096b00 !important;
    border: #096b00 solid 1px !important;
    transition: 0.1s ease!important;
  }
/* Navbar */

/* Homepage  */
/* Homepage  */

/* Showcase */
.showcase {
  margin: 0;
  padding: 0;
  background: linear-gradient(#0000008c, #0000008c), url(/static/media/showcase.c1ed9c0d.png) no-repeat;
  background-size: cover;

  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
}

.showcase .showcase-content {
  margin-top: 30vh;
  color: #ffffff;
  text-align: justify;
}

.showcase .showcase-content h1 {
  font-weight: 100 !important;
  font-family: "Righteous", cursive !important;
}

/* Showcase */

/* Tracking  */

.tracking .form-control {
  border: #fff !important;
  color: #000 !important;
}

.tracking .form-control:focus {
  color: #fff;
  background-color: #fff !important;
  border-color: #fff !important;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgb(1 1 1 / 0%) !important;


}

.tracking .button {
  border-radius: 0 5rem 5rem 0 !important;

}



.tracking a {
  background-color: #fff;
  border-left: 4px solid #096b00 !important;
  border-radius: 0 5rem 5rem 0 !important;

  margin: 0 !important;

}

.tracking span {
  background-color: transparent;
  margin: 0 !important;

}

.tracking box-icon {

  padding: 0 1rem !important;

}

/* Tracking  */


/* What We Do  */

.whatwedo {
  color: #096b00;
  font-family: "Poppins", sans-serif;
}
.whatwedo .img {
  border-radius: 1rem !important;
  padding: 2rem;
}
/* .whatwedo .col-md-3{
  height: 50% !important;
} */

.whatwedo .content {
  margin-top: 2rem;
}

.whatwedo .view-btn button {
  background-color: #096b00;
  border-radius: 50px;
  color: #fff;
  padding: 1.2rem 5rem !important;
  margin-top: 2rem !important;
}
/* What We Do  */

/* How To Send  */
.howtosend {
  color: #096b00;
  font-family: "Poppins", sans-serif;
  background: #94ff8a33;
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.howtosend .content {
  margin-top: 2rem;
}

.howtosend .view-btn button {
  background-color: #096b00;
  border-radius: 50px;
  color: #fff;
  padding: 1.2rem 5rem !important;
  margin-top: 2rem !important;
}
/* How To Send  */

/* How It Works  */
.howitworks {
  padding: 5rem 0;
  /* background-color: #fff; */
}


.howitworks .card1 {
  margin: 5rem 0;
}

.howitworks .card2 {
  margin: 5rem 0;
}

.howitworks h6 {
  max-width: 560px !important;
  text-align: center;
  font-weight: 500 !important;
}

/* How It Works  */

/* Homepage  */
/* Homepage  */

/* Get Quote */
.get-quote {
  background: #096b00ec;
  padding-top: 5rem;
  color: #fff !important;
}

.get-quote .user-btn button {
  margin-top: 7px !important;
  background: white !important;
  color: #096b00 !important;
}
/* Get Quote */


/* Login and SignUp  */
/* Login and SignUp  */
/* Login  */
.login {
  /* height: 100vh !important; */
  overflow-x: hidden;
  overflow-y: hidden;
  font-style: normal;
  font-weight: normal;
  animation:ease;
  margin-top: 3rem !important;
}

.login h6, .login label {
  font-size: smaller !important;

}
.login .login-img {
  height: 100% !important;
    position: fixed !important;
    background-color: #7c4d4d;
    overflow-x: hidden !important;
    overflow-y: hidden !important;
    z-index: 900 !important;

}

.login .login-form {
  margin-left: 50% !important;
}


.login img {
  height: 100vh !important;
  object-fit: cover;
  
}

.login .input-style {
  width: 100%;
  padding: 12px 20px;
  /* margin: 8px 0; */
  display: inline-block;
  border: 1px solid #828282;
  box-sizing: border-box;
  border-radius: 10px;
  font-size: 12px;
  line-height: 24px;
  color: #000;
  box-shadow: none;
  height: 45px;
  margin-bottom: 1rem !important;
  /* -webkit-appearance: textfield !important; */
}

.login .input-style:focus,
.login .input-style:hover {
  border: 1px solid #096b00 !important;
}

.login .btn {
  background-color: #096b00;
  border-radius: 10px;
  color: #ffffff !important;
  text-align: center !important;
  width: 100% !important;
}

.login #togglePassword, #togglePassword2 {
  font-size: 22px;
}
.login .toggle-eye {
  right: 5%;
  top: 25%;
  position: absolute;
  cursor: pointer;
  font-weight: bolder;
  display: block;
}
/* Login  */
/* Login and SignUp  */
/* Login and SignUp  */


/* Footer */
.footer {
  background-color: #111;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.footer h5 {
  font-weight: 100 !important;
}
.footer button {
  background-color: #096b00;
  border-radius: 50px;
}

.footer h6 {
  color: #096b00;
}

.footer-admin {
  background-color: #111;
}

footer .boxicons .box-icon {
  margin-left: 1rem;
  padding: 0.4rem;
  text-align: center !important;
  background-color: #096b00;
  border-radius: 50%;
  border: transparent solid 1.5px !important;
  transition: 0.15s ease;
}

footer .boxicons .box-icon:hover {
  background-color: #111;
  border: #096b00 solid 1.5px !important;

}

/* Footer */

/* Media Query for Mobile device */
/* Media Query for Mobile device */

@media screen and (max-width: 780px) {

 


  /* Login  */
  .login {
    height: -webkit-max-content !important;
    height: max-content !important;
    overflow-y:visible;
  }
  
  .login h6, .login h5 {
    text-align: center !important;
  }

  
  .login .login-form {
    margin-left: 0 !important;
  }
  

  
  .hide-on-small {
    display:none !important;
  }
  }
  


@media screen and (max-width: 580px) {
   /* Global */
   .userback {
    display: none !important;
  }
  /* Global */
  .hide {
    display: contents !important;
  }
  /* Showcase  */

  .showcase .showcase-content {
    margin-top: 35vh;
  }

  /* Showcase  */


  /* How It Works  */
  .howitworks .card2 .row {
    flex-direction: column-reverse !important;
  }
  /* How It Works  */
}

/* Dashboard  */
/* Dashboard  */
.dashboard {
  margin-left: 200px !important;
  margin-top: 4rem !important;
}

/* Dashboard Header  */

.dash-header .welcome {
  margin: 0;
  padding: 0;
  background: linear-gradient(#096b00c2, #096b00c2),
    url(/static/media/pattern2.d195aae4.jpg) no-repeat;
  background-size: cover;
  overflow-x: hidden;
  border-radius: 1rem;
  color: #ffffff !important;
  padding: 1rem 2rem;
}

.dash-header .balance {
  padding: 1.5rem 0rem;
}

.dash-header .balance .spin {
  font-weight: 200 !important;
}

.dash-header .balance h5 {
  font-weight: 100 !important;
}

.dash-header .balance h3 {
  font-weight: 800 !important;
  font-family: "Righteous", cursive !important;
  font-size: xx-large;
}

.dash-header .second {
  margin-top: 1rem;
  background: linear-gradient(#096b00c2, #096b00c2),
    url(/static/media/pattern2.d195aae4.jpg) no-repeat;
  background-size: cover;
  overflow-x: hidden;
  padding: 1rem 1rem;
  border-radius: 1rem;
  color: #ffffff !important;
}

.dash-header .second h6 {
  font-weight: 100 !important;
  text-transform: uppercase !important;
}

.dash-header .third {
  margin-top: 1rem;
  background-size: cover;
  overflow-x: hidden;
  padding: 0.3rem 1rem;
  border-radius: 1rem;
  color: #096b00 !important;
  background: transparent !important;
}

.dash-header .third h6 {
  font-weight: 100 !important;
  margin-top: 0.5rem;
}

.dash-header .box-icon {
  padding: 0.4rem;
  text-align: center !important;
  background-color: #096b001f;
  border-radius: 50%;
  transition: 0.15s ease;
}

/* Dashboard Header  */

/* Transaction History  */
.send-package.first {
  margin-left: 0px !important;
}

/* History Tab */

.trans-history ul {
  background-color: #fff;
  width: -webkit-max-content !important;
  width: max-content !important;
  border-radius: 10px !important;
  padding: 2px !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

.trans-history a.nav-link {
  font-size: 1.2rem;
  color: #000;
  font-weight: 500 !important;
  padding: 0.5rem 2rem;
  transition: 0.7s !important;
}

.trans-history .nav-tabs .nav-link {
  border: 1px solid transparent;
  border-radius: 10px !important;
}

.trans-history .nav-link.active {
  background-color: #096b00 !important;
  border-radius: 10px !important;
  color: #fff !important;
}

.trans-history .nav-link:hover {
  border: 1px solid #096b00 !important;
  border-radius: 10px !important;
  /* color: #000!important; */
}

.trans-history .nav-link:focus {
  color: #fff !important;
}
/* History Tab */

.history .first {
  background: #15e40217;
  margin-bottom: 1rem !important;
  padding: 0.5rem 1rem 0 1rem !important;
  border-radius: 1rem;
}

.history .first .bold-text {
  font-weight: 900 !important;
}

.trans-history a {
  font-size: smaller !important;
}

.trans-history #myTab {
  margin-top: 10px !important;
}

/* Transaction History  */

/* Dashboard  */
/* Dashboard  */

/* Send Package Page */
/* Send Package Page */

.send-package {
  margin-left: 200px !important;
  margin-top: 1rem !important;
}

.send-package .sendshowcase {
  width: auto !important;
  height: 40vh !important;
}

.send-package .sendshowcase .showcase-content {
  margin-top: 7vh !important;
  color: #ffffff;
  text-align: justify;
}

.send-package #myTab {
  margin-top: 3rem;
}
.send-package a.nav-link {
  font-size: 1.2rem;
  color: #000;
  font-weight: 500 !important;
  padding: 0.5rem 2rem;
}

.send-package .nav-tabs .nav-link {
  border: 1px solid transparent;
  border-radius: 10px !important;
}

.send-package .nav-link.active {
  background-color: #096b00 !important;
  border-radius: 10px !important;
  color: #fff !important;
}

.send-package .nav-link:hover {
  border: 1px solid #096b00 !important;
  border-radius: 10px !important;
  color: #000 !important;
}

.send-package .nav-link:focus {
  color: #fff !important;
}

/* Send Package Form  */
.send {
  margin-top: 3rem;
  margin-bottom: 5rem;
  color: #000 !important;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
}

.form {
  margin-top: 2rem;
}

* {
  outline: none;
  -webkit-tap-highlight-color: transparent;
}

.input-style {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #828282;
  box-sizing: border-box;
  border-radius: 10px;
  font-size: 18px;
  line-height: 24px;
  color: #000;
  box-shadow: none;
  height: 55px;
  margin-bottom: 1.5rem !important;
  /* -webkit-appearance: textfield !important; */
}

.send select {
  background: url(/static/media/dropdown.41fe7b26.svg) no-repeat right #ddd0;
  -webkit-appearance: none;
  background-position-x: 96%;
  background-color: white;
  background-position-y: 52%;
  color: #828282 !important;
}

.send .textarea-style {
  height: 100px;
  font-size: 15px;
}

.send .input-style:hover,
.send .input-style:active {
  border: 1px solid #096b00;
}

.send .btn {
  background-color: #096b00;
  color: #ffffff !important;
  text-align: center !important;
  border-radius: 2rem !important;
}

.user-btn button {
  background-color: #096b00;
  border-radius: 10px;
  color: #fff;
  padding: 1.2rem 5rem !important;
  margin-top: 2rem !important;
  /* font-size: large !important; */
}

/* Send Package Form  */

/* Invoice  */
.send-package .invoice {
  margin-top: 5rem;
}

.send-package .invoice .first {
  border: #096b00 solid 1px;
  border-radius: 1rem;
  padding: 1.5rem 1rem;
}

.send-package .card-header {
  border-radius: 1.5rem 1.5rem 0 0 !important;
}
.send-package .card.curve {
  border-radius: 1.5rem !important;
}

.send-package .invoice .card-body h6 {
  font-size: smaller !important;
}
.send-package .invoice .bolder-text {
  font-weight: 600 !important;
}

.invoice-btn button {
  background-color: #096b00;
  border-radius: 2rem;
  color: #fff;
  padding: 0.6rem 0.2 !important;
}

/* Invoice  */

/* Send Package Page */
/* Send Package Page */

/* Payment */
.payment a {
  font-size: smaller !important;
}

.payment #myTab {
  margin-top: 10px !important;
}

.payment ul {
  background-color: #096b0015;
  width: -webkit-max-content !important;
  width: max-content !important;
  border-radius: 10px !important;
  padding: 2px !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

.payment a.nav-link {
  font-size: 1.2rem;
  color: #000;
  font-weight: 500 !important;
  padding: 0.5rem 2rem;
  transition: 0.7s !important;
}

.payment .nav-tabs .nav-link {
  border: 1px solid transparent;
  border-radius: 10px !important;
}

.payment .nav-link.active {
  background-color: #096b00 !important;
  border-radius: 10px !important;
  color: #fff !important;
}

.payment .nav-link:hover {
  border: 1px solid #096b00 !important;
  border-radius: 10px !important;
  /* color: #000!important; */
}

.payment .nav-link:focus {
  color: #fff !important;
}

/* Payment */

/* Get Quote */
.get-quote a {
  font-size: smaller !important;
}

.get-quote .nav-tabs {
  border-bottom: 1px solid transparent !important;
}

.get-quote #myTab {
  margin-top: 10px !important;
}

.get-quote ul {
  background-color: #096b0015;
  width: -webkit-max-content !important;
  width: max-content !important;
  border-radius: 10px !important;
  padding: 2px !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

.get-quote a.nav-link {
  font-size: 1.2rem;
  color: #fff;
  font-weight: 500 !important;
  padding: 0.5rem 2rem;
  transition: 0.7s !important;
}

.get-quote .nav-tabs .nav-link {
  border: 1px solid transparent;
  border-radius: 10px !important;
}

.get-quote .nav-link.active {
  background-color: #fff !important;
  border-radius: 10px !important;
  color: #096b00 !important;
}

.get-quote .nav-link:hover {
  border: 1px solid #fff !important;
  border-radius: 10px !important;
  /* color: #000!important; */
}

.get-quote .nav-link:focus {
  color: #096b00 !important;
}

/* Get Quote */

/* Track */
/* Track */

.send-package .track {
  margin-top: 2rem;
}
.send-package .track p {
  font-size: smaller !important;
}
.send-package .track ol {
  list-style: none !important;
  margin: 0 !important;
  padding: 0 !important;
}
.send-package .track .eachorder {
  display: flex !important;
  margin-bottom: 0.5rem;
}

.send-package .track .eachorder .first {
  /* background: #044b04f1 !important; */
  border: 1px solid #096b00;
  border-radius: 1rem;
  padding: 1rem 1rem;
}

.send-package .track .img {
  margin-bottom: 0 !important;
}

/* Track */
/* Track */

/* Food */
/* Food */

.food {
  margin-top: 2rem;
  margin-bottom: 5rem;
}

.food .card img.card-img-top {
  height: 10rem;
  object-fit: cover;
  /* padding: 1rem; */
}

.food .card {
  border-radius: 0.2rem 0.2rem 1rem 1rem !important;
}

.food .card p {
  margin-bottom: 0 !important;
  font-size: smaller !important;
}

.food .card-body {
  padding: 0.25rem !important;
}

.food .card p.price {
  font-size: large !important;
  font-weight: 600 !important;
  color: #f86801 !important;
}

.food button {
  background-color: #096b00 !important;
  border-radius: 5px !important;
  color: #fff !important;
  padding: 0.5rem 2rem !important;
  /* margin-top: 1rem !important; */
}
.food .btn-cart {
  background-color: #fff !important;
  border-radius: 5px;
  color: #096b00;
  padding: 0.1rem 2rem !important;
  /* margin-top: 1rem !important; */
}

/* Food Details */

.send-package .food-details {
  margin-top: 5rem;
}

.food-details h5 {
  color: #096b00;
  margin-bottom: 2rem;
  text-align: center;
}

.food-details .curve .card {
  border-radius: 2rem !important;
}
.food-details .card-header {
  border-radius: 2rem 2rem 0 0 !important;
}

.food-details .curve .card img {
  border-radius: 2rem !important;
}

.food-details .details h6 {
  font-size: smaller !important;
}

/* Food Details */

/* Food */
/* Food */

/* Profile  */
/* Profile  */

.settings #togglePassword,
#togglePassword2 {
  font-size: 22px;
}
.settings .toggle-eye {
  right: 5%;
  top: 25%;
  position: absolute;
  cursor: pointer;
  font-weight: bolder;
  display: block;
}

/* Profile  */
/* Profile  */

/* Page Not Found */

.pagenotfound {
  margin: 8rem 0;
}

.pagenotfound .gif {
  color: transparent;
  width: 100%;
  height: 100%;
  object-fit: cover;
  text-align: center;
  text-indent: 10000px;
}

.pagenotfound .giffy {
  width: 100%;
  height: 100%;
  max-width: 450px;
  max-height: 450px;
  display: flex;
  overflow: hidden;
  position: relative;
  font-size: 1.25rem;
  align-items: center;
  flex-shrink: 0;
  line-height: 1;
  -webkit-user-select: none;
          user-select: none;
  /* border-radius: 70% 80% 60% 60%; */
  /* border-style: double; */
  border-color: #096b00;
}

.pagenotfound button {
  margin-top: 3rem;
  background-color: #096b00;
  border-radius: 50px;
  color: #ffffff;
}

/* Page Not Found */

/* Media Query for Mobile device */
/* Media Query for Mobile device */

@media screen and (max-width: 780px) {
  /* Login  */
  .login {
    height: -webkit-max-content !important;
    height: max-content !important;
    overflow-y: visible;
  }

  .login h6,
  .login h5 {
    text-align: center !important;
  }

  .hide-on-small {
    display: none !important;
  }
}

@media screen and (max-width: 580px) {
  /* Dashboard  */
  .dashboard {
    margin-left: 0 !important;
  }

  .dashboard .dash-body {
    background: #e9ffe9;
    margin-top: 2rem !important;
    border-radius: 3rem 3rem 0 0 !important;
    height: -webkit-max-content !important;
    height: max-content !important;
    padding-bottom: 3rem !important;
    padding-top: 0.1rem !important;
  }

  .dash-header .first {
    text-align: center !important;
  }

  .dash-header .third h6 {
    font-size: smaller !important;
  }
  /* Dashboard  */

  /* Send Package Page */
  .send-package {
    margin-left: 0 !important;
  }
  /* Send Package Page */
}

/* Media Query for Mobile device */
/* Media Query for Mobile device */

/* Admin Dashboard  */
/* Admin Dashboard  */

/* Dashboard  */
.admin-dashboard .back {
  display: none !important;
}

.welcome-admin {
  padding: 1rem 2rem;
  border-radius: 1rem;
  margin-bottom: 2rem;
  background-color: #272626;
  color: #fff;
}

.welcome-admin .first {
  padding: 1.5rem 0rem;
}
/* Dashboard  */

/* Nav Buttons  */

.nav-button {
  /* background-color: #272626; */
  border: #272626 solid 1px;
  border-radius: 1rem;
  padding: 2rem 1rem;
}

.nav-button .first {
  background-color: #fcfff8;
  padding: 1rem 1rem;
  text-align: center !important;
  border-radius: 1rem;
  color: black !important;
  margin-bottom: 1rem;
}

.nav-button .first a {
  color: black !important;
}

.nav-button .navb:hover {
  background-color: #dfdfdf;
}

/* Nav Buttons  */

/* Admin Dashboard  */
/* Admin Dashboard  */

.userlist {
  /* background-color: red !important; */
  overflow-x: scroll !important;
}

.userlist #table {
  overflow-x: scroll !important;
}

/* All Order */
.trans-history .nav-tabs {
  overflow-x: scroll !important;
}
.trans-history .nav-tab {
  overflow-x: scroll !important;
  width: 100vw !important;
}

/* .trans-history .nav-tab::-webkit-scrollbar {
  width: 2px !important;
} */
.trans-history .nav-tab::-webkit-scrollbar-track {
  border: none;
  background-color: transparent !important;
}
.trans-history .nav-tab::-webkit-scrollbar-thumb {
  background: #096b001c !important;
  height: 1vh !important;
  border-radius: 10px !important;
}
/* All Order */

/* Create Order  */
.send-package.admin-create-order {
  margin-left: 0px !important;
  margin-top: 2rem !important;
}
/* Create Order  */

@media screen and (max-width: 580px) {
  /* Admin Dashboard  */
  /*  Dashboard  */
  .welcome-admin h6 {
    text-align: center !important;
  }
  /*  Dashboard  */
  /* Admin Dashboard  */
}

/* Navbar */

.usernav nav {
  background-color: #fafafa !important;
  transition: 0.5s ease !important;
  padding: 0.5rem 1rem !important;
  z-index: 1001 !important;
  text-align: left !important;
  border-radius: 0 0 1.5rem 1.5rem !important;
  /* box-shadow: none !important; */

  /* height: 7rem ; */
}

.usernav .active1 {
  border-right: #15df02 5px solid;
  color: #b4f3ae !important;
  transition: 0.1s ease !important;
  /* background: #fafafa !important;
border-radius: 4px;
color: #096b00 !important; */
}
.usernav .active2 {
  background: yellow !important;
}

.usernav nav button.navbar-toggler {
  background-color: none !important;
}

.usernav .navbar-toggler-icon {
  background: none !important;
}





.usernav nav .nav-item a {
  width: 100% !important;
  /* padding-left: 0.4rem !important; */
}

.usernav nav .nav-item {
  display: flex !important;
  align-items: center !important;
  width: auto !important;
  margin-left: 0.5rem !important;
  /* margin-right: 0.5rem !important; */
  /* background: yellow; */
}

.usernav nav .nav-item a:hover {
  border-right: #fff 5px solid !important;
  color: #fff !important;
  transition: 0.1s ease !important;
  background: #096b0059;
  border: 0 5px 0 0 !important;
}

/* usernav dropdown  */

.usernav nav .nav-item .dropdown-user {
  /* border: none !important ; */
  background: white !important;
  text-align: center !important;
  border-radius: 0.3rem !important;
  font-size: x-small;
}

.usernav nav .dropdown .user:hover {
  border-right: none !important;
  color: #fff !important;
  transition: 0.1s ease !important;
  background: #096b0059;
  border: 0 5px 0 0 !important;
}

.details p{
  font-size: 17px !important;
  margin-bottom: 0 !important;
}

.usernav nav .details a {
  width: 0 !important;
  padding-left: 0.4rem !important;
}

.usernav nav .details a:hover {
  border-right: none !important;
  color: none !important;
  transition: none !important;
  background: none;
  border: none !important;
}


.usernav nav .details .btn {
  margin: 0 !important;
  padding: 0.4rem 0.8rem !important;
}

.usernav nav .btn {
  text-transform: uppercase !important;
  padding: 0.8rem 1rem !important;
  width: 7rem !important;
}


.icon {
  position: relative;
  /* font-size: 48px; */
  color: #f9f9f9;
}

.icon::after {
  content: "";
  position: absolute;
  text-align: center !important;
  /* top: 6px; */
  right: -5px;
  width: 12px;
  height: 12px;
  font-size: 8px;
  border-radius: 50%;
  background: red;
  color: #f9f9f9;
}
/* Admin Nav  */
/* .usernav .adminnav {

} */
/* Admin Nav  */

/* Navbar */
.sidenav {
  height: 100%;
  width: 200px;
  position: fixed;
  top: 0rem;
  /* padding-top: 3rem; */
  left: 0;
  background-color: #096b00;
  overflow-x: hidden;
  transition: cubic-bezier(0.075, 0.82, 0.165, 1) 0.5s;
  z-index: 1002 !important;
}

.sidenav ul li {
  margin-bottom: 1rem;
}

.sidenav .nav-item a {
  color: #fafafa;
  font-size: 1rem;
  display: block;
  padding: 0.5rem 1rem;
  margin: 0 0rem;
}

.sidenav .btn {
  text-transform: uppercase !important;
  padding: 0.8rem 1.3rem !important;
  border: #fafafa solid 2px !important;
  border-radius: 5px;
  color: #fafafa;
}
.sidenav .btn:hover {
  background-color: #fafafa !important;
  transition: 0.55s ease !important;
  color: #000000 !important;
}

.sidenav .closebtn {
  position: absolute;
  /* top: 25px; */
  right: 15px;
  font-size: 36px;
  margin-left: 50px;
  display: none;
  /* background-color: red; */
  border-radius: 2rem;
  /* border: 1px white solid; */
}

.name {
  text-align: center !important;
  margin-left: 0.5rem !important;
}

.sidenav h5 {
  font-weight: 100 !important;
  color: aliceblue;
}
.sidenav button.btn1 {
  background-color: #096b00 !important;
  border-radius: 50px !important;
  padding: 1rem 3rem !important;
  border: none !important;
}

.sidenav button.btn1:hover {
  background-color: #fff !important;
  color: #096b00 !important;
}

#main {
  transition: margin-left 0.5s;
  padding: 16px;
}

@media screen and (max-width: 991px) {
  .usernav .navbar ul {
    display: none !important;
  }
}

@media screen and (max-width: 580px) {
  /* Navbar */
  .nav-name {
    display: none !important;
  }

  .sidenav {
    width: 0;
    /* padding-top: 3rem; */
  }

  .sidenav .closebtn {
    display: block !important;
  }
  h5.name {
    text-align: right !important;
    margin-left: 0.5rem !important;
  }
}

@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }
  .sidenav a {
    font-size: 18px;
  }
}

