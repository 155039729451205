/* Admin Dashboard  */
/* Admin Dashboard  */

/* Dashboard  */
.admin-dashboard .back {
  display: none !important;
}

.welcome-admin {
  padding: 1rem 2rem;
  border-radius: 1rem;
  margin-bottom: 2rem;
  background-color: #272626;
  color: #fff;
}

.welcome-admin .first {
  padding: 1.5rem 0rem;
}
/* Dashboard  */

/* Nav Buttons  */

.nav-button {
  /* background-color: #272626; */
  border: #272626 solid 1px;
  border-radius: 1rem;
  padding: 2rem 1rem;
}

.nav-button .first {
  background-color: #fcfff8;
  padding: 1rem 1rem;
  text-align: center !important;
  border-radius: 1rem;
  color: black !important;
  margin-bottom: 1rem;
}

.nav-button .first a {
  color: black !important;
}

.nav-button .navb:hover {
  background-color: #dfdfdf;
}

/* Nav Buttons  */

/* Admin Dashboard  */
/* Admin Dashboard  */

.userlist {
  /* background-color: red !important; */
  overflow-x: scroll !important;
}

.userlist #table {
  overflow-x: scroll !important;
}

/* All Order */
.trans-history .nav-tabs {
  overflow-x: scroll !important;
}
.trans-history .nav-tab {
  overflow-x: scroll !important;
  width: 100vw !important;
}

/* .trans-history .nav-tab::-webkit-scrollbar {
  width: 2px !important;
} */
.trans-history .nav-tab::-webkit-scrollbar-track {
  border: none;
  background-color: transparent !important;
}
.trans-history .nav-tab::-webkit-scrollbar-thumb {
  background: #096b001c !important;
  height: 1vh !important;
  border-radius: 10px !important;
}
/* All Order */

/* Create Order  */
.send-package.admin-create-order {
  margin-left: 0px !important;
  margin-top: 2rem !important;
}
/* Create Order  */

@media screen and (max-width: 580px) {
  /* Admin Dashboard  */
  /*  Dashboard  */
  .welcome-admin h6 {
    text-align: center !important;
  }
  /*  Dashboard  */
  /* Admin Dashboard  */
}
